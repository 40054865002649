:root {
  --cabinet-primary-color: #cdb39f;
  --cabinet-secondary-color: #e8dcd3;
  --converse-primary-color: #00df9b;
  --converse-secondary-color: #a8ff78;
  --organiztion-primary-color: #8898aa;
  --organiztion-secondary-color: #888aaa;
  --stockpile-primary-color: #005a76;
  --stockpile-secondary-color: #00c3ff;
  --workforce-primary-color: #d0021b;
  --workforce-secondary-color: #fe7182;
  --touchbase-primary-color: #005a76;
  --touchbase-secondary-color: #8eb9ed;
}

.text-organization {
  color: var(--organiztion-primary-color);
}

.text-cabinet {
  color: var(--cabinet-primary-color);
}

.text-converse {
  color: var(--converse-primary-color);
}

.text-stockpile {
  color: var(--stockpile-primary-color);
}

.text-touchbase {
  color: var(--touchbase-primary-color);
}

.bg-gradient-cabinet {
  background: linear-gradient(
    87deg,
    var(--cabinet-primary-color) 0,
    var(--cabinet-secondary-color) 100%
  ) !important;
}
.bg-gradient-touchbase {
  background: linear-gradient(87deg, #4a90e2 0, #8eb9ed 100%) !important;
}
.bg-gradient-bookkeeper {
  background: linear-gradient(87deg, #1cefff 0, #c0c0aa 100%) !important;
}
.bg-gradient-projects {
  background: linear-gradient(87deg, #f5a623 0, #f8c46e 100%) !important;
}
.bg-gradient-timetable {
  background: linear-gradient(87deg, #f64f59 0, #12c2e9 100%) !important;
}
.bg-gradient-greet {
  background: linear-gradient(87deg, #636fa4 0, #e8cbc0 100%) !important;
}
.bg-gradient-converse {
  background: linear-gradient(87deg, #78ffd6 0, #a8ff78 100%) !important;
}
.bg-gradient-stockpile {
  background: linear-gradient(87deg, #005a76 0, #00c3ff 100%) !important;
}

.bg-gradient-workforce {
  background: linear-gradient(
    var(--workforce-primary-color) 0,
    var(--workforce-secondary-color) 100%
  ) !important;
}

.bg-workforce {
  background: var(--workforce-secondary-color) !important;
  color: #fff;
}

.bg-organiztion {
  background: var(--organization-secondary-color) !important;
  color: #fff;
}
